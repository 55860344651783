var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        staticClass:
          "relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl",
        staticStyle: { "min-height": "19rem" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass:
                  "hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12",
                attrs: { viewBox: "0 0 100 100", preserveAspectRatio: "none" }
              },
              [_c("polygon", { attrs: { points: "50,0 100,0 50,100 0,100" } })]
            )
          ]
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3)
      ]
    ),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg",
        staticStyle: { "min-height": "19rem" }
      },
      [
        _c("img", {
          staticClass:
            "absolute inset-0 w-full h-full object-cover object-center",
          attrs: { src: "", alt: "" }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "absolute inset-0 w-full h-full bg-indigo-900 opacity-75"
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-12 md:pr-24 md:pl-16 md:py-12" }, [
      _c("p", { staticClass: "text-gray-600" }, [
        _c("span", { staticClass: "text-gray-900" }),
        _vm._v("Blech")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-baseline mt-3 text-indigo-600 hover:text-indigo-900 focus:text-indigo-900",
          attrs: { href: "" }
        },
        [
          _c("span", [_vm._v("Learn more about our users")]),
          _vm._v(" "),
          _c("span", { staticClass: "text-xs ml-1" }, [_vm._v("➜")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "absolute top-0 mt-32 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline"
      },
      [
        _c(
          "span",
          { staticClass: "block", staticStyle: { transform: "scale(-1)" } },
          [_vm._v("➜")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "absolute top-0 mt-32 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline"
      },
      [
        _c(
          "span",
          { staticClass: "block", staticStyle: { transform: "scale(1)" } },
          [_vm._v("➜")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex items-center pt-5 justify-between" },
      [
        _c(
          "button",
          {
            staticClass: "px-2 opacity-50 hover:opacity-100 focus:opacity-100"
          },
          [
            _c("img", {
              staticClass: "w-full",
              staticStyle: { "max-height": "60px" },
              attrs: {
                src:
                  "https://stripe.com/img/v3/payments/overview/logos/kickstarter.svg",
                alt: ""
              }
            })
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }