<template>
    <div class="container">
        <slot></slot>
<!--        <spotify-widget></spotify-widget>-->
<!--        <slanty-card></slanty-card>-->
    </div>
</template>

<script>
    import spotifyWidget from "./components/spotifyWidget";
    import slantyCard from "./components/slantyCard";

    export default {
        name: "App",
        components: {
            'spotify-widget': spotifyWidget,
            'slanty-card': slantyCard
        }
    };
</script>