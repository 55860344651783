var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isPlaying,
          expression: "isPlaying"
        }
      ],
      model: {
        value: _vm.song,
        callback: function($$v) {
          _vm.song = $$v
        },
        expression: "song"
      }
    },
    [
      _c(
        "p",
        {
          model: {
            value: _vm.song,
            callback: function($$v) {
              _vm.song = $$v
            },
            expression: "song"
          }
        },
        [_vm._v("Title: " + _vm._s(_vm.song.title))]
      ),
      _vm._v(" "),
      _c("p", [_vm._v("Artists: " + _vm._s(_vm.song.artists))]),
      _vm._v(" "),
      _c("p", [_c("img", { attrs: { src: _vm.song.thumb } })])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }