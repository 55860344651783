<template>
    <div v-model="song" v-show="isPlaying">
        <p v-model="song">Title: {{ song.title }}</p>
        <p>Artists: {{ song.artists }}</p>
        <p><img :src="song.thumb"></p>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                song: []
            }
        },
        methods: {
            fetchCurrentSong() {
                axios.get('/spotify/current')
                .then(response => {
                   this.song = response.data;
                });
            }
        },

        computed: {
            isPlaying: function() {
                return Boolean(this.song.title);
            }
        },
        beforeMount() {
            this.fetchCurrentSong();
        }
    }
</script>